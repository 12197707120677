<template>
  <section class="relative">
    <div v-if="loading" class="rounded-md icono-carga p-8 pos-loading">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div id="grafica-autorizacion" class="box w-full flex flex-wrap items-center justify-center">
      <div
      ref="elChart"
      style="height:500px; "
      class="w-full rounded-md"
      />
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { apiclasificacionTipoPqr, apiclasificacionPqr } from '../../services'
import * as echarts from 'echarts'
// import currency from 'currency.js'
import { useStore } from 'vuex'

const navegadorVentanaAncho = 1024
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  name: 'Card Clasificacion PQR',
  setup (props, context) {
    const store = useStore()
    const loading = ref(false)
    const elChart = ref(null)
    let chartInstance = reactive({})
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const laData = ref([])
    const TipoData = ref([])

    const getDataTotales = () => {
      // console.log('La data: ', laData.value)
      return laData.value.map(a => {
        return {
          value: a.cantidad,
          name: a.tipo
        }
      })
    }

    const getDataTipos = (tipo = '') => {
      var array = []
      var idx = 0
      TipoData.value.map(a => {
        var obj = { value: 0, name: a.Clasificacion }
        switch (tipo) {
          case 'Denuncia':
            obj.value = a.Denuncia
            break
          case 'Petición':
            obj.value = a.Peticion
            break
          case 'Queja':
            obj.value = a.Queja
            break
          case 'Reclamo':
            obj.value = a.Reclamo
            break
          case 'Solicitud de Información':
            obj.value = a.Solicitud
            break
          case 'Sugerencia':
            obj.value = a.Sugerencia
            break
          case 'Felicitaciones':
            obj.value = a.Felicitaciones
            break
          default:
            break
        }
        if (obj.value != null) {
          array[idx] = obj
          idx++
        }
      })
      // console.log('Array: ', array)
        return array
    }

    const getData = () => {
      return laData.value.map(a => a.total)
    }
    const setLaData = (values) => {
      laData.value = values.data
      getDataTotales()
    }
    const setTipoData = (values) => {
      TipoData.value = values.data
    }

    const initChart = () => {
      chartInstance = echarts.init(elChart.value, isDark.value ? 'dark' : 'light')
      const colorPalette = ['#5470c6', '#fac858', '#91cc75', '#ee6666', '#3ba272', '#73c0de', '#F2E2CE', '#9a60b4', '#ea7ccc', '#C1D4D9', '#591E2E']
      const option = {
        // darkMode: true,
        backgroundColor: isDark.value ? '#313A55' : '',
        grid: {
          left: '8%',
          right: '0%',
          bottom: '2%',
          top: '15%'
        },
        title: {
          text: 'PQR por Tipología',
          left: 15,
          top: 15
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          formatter (a) {
            let Tipos = ''
            var array = getDataTipos(a.data.name)
            console.log('A value: -> ', a.value)
              Tipos = Tipos + `<div class="title-tool"><p style="width:75%">${a.data.name}<p><p style="width:25%;text-align: right;">${new Intl.NumberFormat('de-ES').format(a.value)}<p></div><br/>`
              for (let idx = 0; idx < array.length; idx++) {
              Tipos = Tipos + `<div style="display:flex;width:250px;"><p style="width:75%">${array[idx].name}<p><p style="width:25%;text-align: right;">${new Intl.NumberFormat('de-ES').format(array[idx].value)}<p></div>`
              }
            return Tipos
          }
        },
        legend: {
          orient: 'horizontal',
          left: 15,
          top: 45
        },
        // backgroundColor: 'blue',
        series: [
          {
            type: 'pie',
            radius: ['20%', '45%'],
            top: 80,
            color: colorPalette,
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 9,
              borderColor: '#fff',
              borderWidth: 2
            },
            labelLine: {
              length: 4
            },
            label: {
              width: 100,
              fontSize: 11,
              formatter (a) {
                  return `${a.data.name}  \n ${a.percent}%`
                }
            },
            data: []
          }
        ]
      }
      chartInstance.setOption(option)
      loadEvents()
    }

    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        setChart()
        chartInstance.resize()
      })
    }
    const setChart = () => {
        const option = {
          position: (pos) => {
            if (window.innerWidth > navegadorVentanaAncho) {
              return pos[0]
            } else {
              return ['5%', '0']
            }
          },
          series: [
            {
              data: getDataTotales()
            }
          ],
          grid: {
            left: '8%',
            right: '0%',
            bottom: '2%',
            top: '55%'
          }
        }
      chartInstance.setOption(option)
    }

    /*
    * Hooks
    */
    // watch(props.filters, (filter) => {
    //   if ([null, undefined, ''].includes(filter.client) ||
    //     [null, undefined, ''].includes(filter.year)
    //   ) return
    //   fetchResults(filter)
    // })

    const fetchResults = (filter) => {
      loading.value = true
      apiclasificacionTipoPqr(filter).then(({ data }) => {
        setLaData(data)
        setChart()
      }).catch((err) => {
        console.log(err.message)
      }).finally(() => {
        loading.value = false
      })
      apiclasificacionPqr(filter).then(({ data }) => {
        setTipoData(data)
        setChart()
      }).catch((err) => {
        console.log(err.message)
      }).finally(() => {
        loading.value = false
      })
    }
    onMounted(() => {
      // console.log(111, context)
      initChart(props.filters)
    })

    return {
      fetchResults,
      elChart,
      loading,
      getData,
      getDataTotales
    }
  }
}
</script>

<style>
@media print {
  #grafica-autorizacion{
    overflow:hidden;
    width: 100%;
    /*page-break-before:always;*/
  }
}
.pos-loading {
  height:350px;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  width:100%
}

</style>
