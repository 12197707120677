<template>
  <section>
    <div v-if="loading" class="rounded-md icono-carga p-8 pos-loading">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="box w-full" style="overflow:hidden;">
      <div
      id="grafica"
      ref="elGrafica"
      style="height:450px;"
      class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref } from 'vue'
import { apiPacientesAtendidos } from '../../services'
import { useStore } from 'vuex'
// import currency from 'currency.js'
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const colorLabel = ref('white')
    const loading = ref(false)
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    /*
    * Refs and reactives
    */
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    /*
    * Computeds
    */

    /*
    * Methods
    */
    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        grid: {
          left: 10,
          right: 30,
          bottom: 40,
          top: 85,
          containLabel: true
        },
        title: {
          text: 'Pacientes Atendidos',
          top: 15,
          left: 15
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            saveAsImage: {}
          }
        },
        tooltip: {
          formatter (a) {
            return `${a.name.split(' ').join('<br>')} <br> ${a.value}`
          }
        },
        legend: { },
        xAxis: {
          data: [],
          axisLabel: {
            interval: 0,
            fontSize: 10,
            height: 20,
            width: 70,
            overflow: 'break'
          }
        },
        yAxis: [
          {
            show: false
          }
        ],
        series: [
          {
            // name: 'Sales',
            type: 'bar',
            showBackground: true,
            data: [],
            label: {
              show: true,
              color: colorLabel.value,
              // formatter (d) {
              //   return `${new Intl.NumberFormat('de-ES').format(d.value)}`
              // },
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }
      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', evt => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          data: getChartLabels()
        },
        series: [
          {
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              // formatter (d) {
              //   return `${new Intl.NumberFormat('de-ES').format(d.value)}`
              // },
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }
      option.xAxis.show = true
      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }
      chart.setOption(option)
    }

    const getChartLabels = () => {
      return chartData.value.map(a => months[a.Mes - 1])
    }

    const getChartData = () => {
      return chartData.value.map(a => new Intl.NumberFormat('de-ES').format(a.Cantidad))
    }

    const fetchPacAtend = () => {
      loading.value = true
      return apiPacientesAtendidos(props.filters).then(({ data }) => {
        chartData.value = data.data
        // console.log('apiPacientesAtendidos', data)
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
      }).finally(() => {
        loading.value = false
      })
    }
    /*
    * Hooks
    */
    // watch(props.filters, (filter) => {
    //   if ([null, undefined, ''].includes(filter.client) ||
    //     [null, undefined, ''].includes(filter.year)
    //   ) return

    //   fetchPacAtend()
    // })

    onMounted(() => {
      initChart()
      // fetchPacAtend()
    })
    return {
      fetchPacAtend,
      elGrafica
    }
  }
}
</script>

<style>

</style>
