<template>
  <section class="w-full">
    <div class="w-full flex justify-between items-center my-3">
      <h1 class="font-bold text-lg capitalize">Informe Ejecución</h1>
      <div>
        <button
          type="button"
          class="bg-red-400 text-white px-3 py-2 rounded-md "
          @click="handleDownloadManual"
        >
          Manual
        </button>
        <button
          type="button"
          class="bg-primary-1 text-white px-3 py-2 ml-3 rounded-md "
          @click="handleDownloadPdf"
        >
          Descargar PDF
        </button>
        <button
          type="button"
          class="bg-green-600 text-white px-3 py-2 ml-3 rounded-md "
          @click="handleDownloadXls"
        >
          Descargar XLS
        </button>
      </div>
    </div>
    <FiltrosEjecucion class="mt-2 mb-4 h-full" v-model="filters" @buscar="buscar"/>
    <ResumenTotales ref="ResumenTotales" class="my-2" :filters="filters"/>
    <button v-if="user.user_id == 472"
      type="button"
      class="bg-primary-1 text-white px-3 py-2 rounded-md "
      @click="modalReporteRadicacion"
    >
      Reporte Radicación Anexo
    </button>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2 mt-4">
      <GraficaAutorizacion ref="GraficaAutorizacion" :filters="filters"/>
      <MapaColombia v-model="filters"/>
    </div>
    <div class="w-full mt-4 mb-2">
      <GraficaAcumulados ref="GraficaAcumulados" :filters="filters"/>
    </div>
    <div class="w-full h-full flex flex-col mt-4 mb-2">
      <TopIps ref="GraficaIps" :filters="filters"/>
    </div>
    <div class="w-full h-full flex flex-col my-2">
      <TopMdicos ref="GraficaMedicos" class="my-2" :filters="filters" />
    </div>
    <div class="w-full h-full flex flex-col my-2">
      <TopPacientes ref="GraficaPacientes" class="my-2" :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Tecnovigilancia</h1>
    <div class="w-full my-3">
      <CardsTecnoFarma ref="CardsTecnoFarma" :filters="filters" />
    </div>
    <div class="w-full my-3">
      <TecnicoVigilancia ref="TecnoVigilancia" :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Farmacovigilancia</h1>
    <div class="w-full my-3">
      <CardsFarma ref="CardsFarma" :filters="filters" />
    </div>
    <div class="w-full my-3">
      <FarmacoVigilancia ref="FarmacoVigilancia" :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Auditoría Médica</h1>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 my-2 mt-4">
      <PacientesDiagnostico ref="PacientesDiagnostico" :filters="filters"/>
      <PacientesPoliformulados ref="PacientesPoliformulados" :filters="filters"/>
      <AnalisisPoliPacientes ref="AnalisisPoliPacientes" :filters="filters"/>
    </div>

    <!-- nuevos cambios -->
    <div v-if="$can('pharmasan.dashboard.indicadores-clientes.servicio-cliente')">
      <div class="col-span-12 mt-5">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Servicio al Cliente</h2>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-12 my-3">
        <GraficaPqrClasificacion ref="GraficaPqrClasificacion" :filters="filters" />
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2 mt-4">
        <GraficaPqrCliente ref="GraficaPqrCliente" :filters="filters" />
        <GraficaPqrMedio ref="GraficaPqrMedio" :filters="filters" />
      </div>
      <div class="grid grid-cols-1 gap-4 my-2 mt-4">
        <GraficaPacientesAtenidos ref="GraficaPacientesAtendidos" :filters="filters" />
      </div>
    </div>
<!--    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:95px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Digiturno</h2>
          <FiltroDigiturno  @updateFilters="updateFilters"/>
        </div>
      </div>
    </div>
    <ContenedorDigiturno ref="contenedorDigiturnoRef" :filters="filtersDigi" />-->

    <div class="col-span-12 mt-5">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Gestión MIPRES</h2>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Mipres ref="CardMipres" :filters="filters" />
      </div>
    </div>

    <div class="col-span-12 mt-5">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Historia clinica</h2>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <History/>
      </div>
    </div>

    <div v-if="$can('pharmasan.dashboard.indicadores-clientes.solicitudes')" class="col-span-12 mt-5">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Solicitudes</h2>
      </div>
    </div>
    <div v-if="$can('pharmasan.dashboard.indicadores-clientes.solicitudes')"  class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Solicitudes ref="CardSolicitudes" :filters="filters"/>
      </div>
    </div>
    <Dialog header="Reporte Radicación Anexo" v-model:visible="displayBasic" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
      <div class="form-inline my-5 flex justify-center">
          <label class="mr-2">Rango de Fechas:</label>
          <DatePicker v-model="range" is-range mode="date" >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
                <svg
                  class="w-4 h-4 mx-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
                <input
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </div>
            </template>
          </DatePicker>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
            <Button label="Aceptar" icon="pi pi-check" @click="reporteRadicacionAnexo" autofocus />
        </template>
    </Dialog>
  </section>
</template>

<script>

import { warning } from '../../../../../libs/mensajes'
import FiltrosEjecucion from './components/filtros'
import TopIps from './components/topIps'
import TopMdicos from './components/topMedicos'
import TopPacientes from './components/topPacientes'
import GraficaAutorizacion from './components/graficaAutorizacion'
import GraficaAcumulados from './components/graficaAcumulados'
import ResumenTotales from './components/resumenTotales'
import MapaColombia from './components/mapaColombia'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import axios from 'axios'
import TecnicoVigilancia from './components/tecnicoVigilancia'
import FarmacoVigilancia from './components/farmacoVigilancia'
import CardsTecnoFarma from './components/cardsTecnoFarma'
import CardsFarma from './components/cardsFarma'
import PacientesDiagnostico from './components/AuditoriaMedica/PacientesDiagnostico'
import PacientesPoliformulados from './components/AuditoriaMedica/PacientesPoliformulados'
import AnalisisPoliPacientes from './components/AuditoriaMedica/AnalisisPoliPacientes'
import GraficaPqrClasificacion from './components/GraficaPqrClasificacion'
import GraficaPqrCliente from './components/GraficaPqrCliente'
import GraficaPqrMedio from './components/GraficaPqrMedio'
import GraficaPacientesAtenidos from './components/pacientesAtendidos'
// import ContenedorDigiturno from './components/digiturno'
// import FiltroDigiturno from './components/digiturno/filtrosDigiturno.vue'
import Mipres from './components/mipres'
import History from './components/historiaClinica'
import Solicitudes from './components/solicitudes'
// import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
import { DatePicker } from 'v-calendar'
import { helper } from '../../../../../utils/helper'
// import { useStore } from '@/store'

export default {
  components: {
    DatePicker,
    Solicitudes,
    History,
    FiltrosEjecucion,
    TopIps,
    TopMdicos,
    TopPacientes,
    GraficaAutorizacion,
    GraficaAcumulados,
    ResumenTotales,
    MapaColombia,
    TecnicoVigilancia,
    FarmacoVigilancia,
    CardsTecnoFarma,
    CardsFarma,
    PacientesDiagnostico,
    PacientesPoliformulados,
    AnalisisPoliPacientes,
    GraficaPqrClasificacion,
    GraficaPqrCliente,
    GraficaPqrMedio,
    GraficaPacientesAtenidos,
    // ContenedorDigiturno,
    // FiltroDigiturno,
    Mipres
  },
  setup () {
    const range = ref({
      start: new Date(),
      end: new Date()
    })
    const fechas = ref({
      fecha_inicial: helper.formatDate(new Date(), 'YYYY-MM-DD'),
      fecha_final: helper.formatDate(new Date(), 'YYYY-MM-DD')
    })

    watch(range, () => {
      actualizarFechas()
    })

    const actualizarFechas = () => {
      var fecha = range.value
      const actualizarFiltroPadreProps = {
        fecha_inicial: helper.formatDate(fecha.start, 'YYYY-MM-DD'),
        fecha_final: helper.formatDate(fecha.end, 'YYYY-MM-DD')
      }
      fechas.value = actualizarFiltroPadreProps
    }
    // const store = useStore()
    const getYearNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        if (dayjs().month() === 0) {
          return new Date().getFullYear() - 1
        }
      }
      return new Date().getFullYear()
    }
    const getMonthNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        return dayjs().subtract(1, 'month').month() + 1
      }
      return new Date().getMonth() + 1
    }
    const filtro = ref({
      year: getYearNow(),
      month: getMonthNow()
    })
    // const contenedorDigiturnoRef = ref()
    const updateFilters = (filtros) => {
      filtersDigi.fecha_inicial = filtros.fecha_inicial
      filtersDigi.fecha_final = filtros.fecha_final
      // updateClient()
      // contenedorDigiturnoRef.value.fetchResults(filtersDigi)
    }
    const filters = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null,
      regimen: null,
      genero: null,
      edad1: null,
      edad2: null
    })
    const filtersDigi = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null,
      regimen: null,
      genero: null,
      edad1: null,
      edad2: null
    })

    const handleDownloadManual = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API}`
      const ruta = '/pdf/Manual_Indicadores_Clientes.pdf'
        window.open(url + ruta, '_blank')
    }
    const handleDownloadPdf = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-pdf`
      const res = axios.getUri({ url, params: filters })
      if (filters.client !== null) {
        window.open(res, '_blank')
      } else {
          warning('Seleccione un cliente')
        }
    }
    const handleDownloadXls = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-informe-xls`
         const res = axios.getUri({ url, params: filters })
         if (filters.client !== null) {
           window.open(res, '_blank')
         } else {
          warning('Seleccione un cliente')
        }
    }

    const ResumenTotales = ref()
    const GraficaAutorizacion = ref()
    const GraficaAcumulados = ref()
    const GraficaIps = ref()
    const GraficaMedicos = ref()
    const GraficaPacientes = ref()
    const CardsTecnoFarma = ref()
    const TecnoVigilancia = ref()
    const CardsFarma = ref()
    const FarmacoVigilancia = ref()
    const PacientesDiagnostico = ref()
    const PacientesPoliformulados = ref()
    const AnalisisPoliPacientes = ref()
    const GraficaPqrClasificacion = ref()
    const GraficaPqrCliente = ref()
    const GraficaPqrMedio = ref()
    const GraficaPacientesAtendidos = ref()
    const CardMipres = ref()
    const CardSolicitudes = ref()

    const buscar = (filters) => {
      // updateClient()
      ResumenTotales.value.fetchTotales(filters)
      GraficaAutorizacion.value.fetchResults(filters)
      GraficaAcumulados.value.fetchResults(filters)
      GraficaIps.value.fetchIps(filters)
      GraficaMedicos.value.fetchMedicos(filters)
      GraficaPacientes.value.fetchPacientes(filters)
      CardsTecnoFarma.value.fetchVigilancia(filters)
      TecnoVigilancia.value.fetchResults(filters)
      CardsFarma.value.fetchVigilancia(filters)
      FarmacoVigilancia.value.fetchResults(filters)
      PacientesDiagnostico.value.fetchResults(filters)
      PacientesPoliformulados.value.fetchResults(filters)
      AnalisisPoliPacientes.value.fetchResults(filters)
      GraficaPqrClasificacion.value.fetchResults(filters)
      GraficaPqrCliente.value.fetchResults(filters)
      GraficaPqrMedio.value.fetchResults(filters)
      GraficaPacientesAtendidos.value.fetchPacAtend(filters)
      CardMipres.value.fetchResults(filters)
      CardSolicitudes.value.getSolictudes(filters)
    }

    const updateClient = () => {
      filters.client = nameClient.value.firt_name
      filtersDigi.client = nameClient.value.firt_name
    }
    const store = useStore()
    const nameClient = computed(() => {
      return store.getters['auth/getPeople']
    })

    const user = store.getters['auth/user']
    const displayBasic = ref(false)
    const modalReporteRadicacion = () => {
        displayBasic.value = true
    }

    const reporteRadicacionAnexo = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-informe-radicacion-anexo`
         const res = axios.getUri({ url, params: fechas.value })
         window.open(res, '_blank')
    }

    onMounted(() => {
      setTimeout(() => {
        filters.client = nameClient.value.firt_name
        filtersDigi.client = nameClient.value.firt_name
      }, 1000)
      actualizarFechas()
    })

    return {
      // updateClient,
      user,
      range,
      fechas,
      DatePicker,
      modalReporteRadicacion,
      displayBasic,
      reporteRadicacionAnexo,
      buscar,
      filters,
      filtersDigi,
      handleDownloadManual,
      handleDownloadPdf,
      handleDownloadXls,
      filtro,
      updateFilters,
      ResumenTotales,
      GraficaAutorizacion,
      GraficaAcumulados,
      GraficaIps,
      GraficaMedicos,
      GraficaPacientes,
      CardsTecnoFarma,
      TecnoVigilancia,
      CardsFarma,
      FarmacoVigilancia,
      PacientesDiagnostico,
      PacientesPoliformulados,
      AnalisisPoliPacientes,
      GraficaPqrClasificacion,
      GraficaPqrCliente,
      GraficaPqrMedio,
      GraficaPacientesAtendidos,
      CardMipres,
      CardSolicitudes,
      // contenedorDigiturnoRef,
      updateClient
    }
  }
}
</script>
