import http from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report`
const apiExecutionReportClient = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report-cliente`
const apiSelectFilter = () => http.get(`${api}/select-filter`)

const apiAuthorizationReason = (options) => http.get(
  `${api}/authorization-reason`,
  { params: { ...options } })

const apiTopDoctors = (options) => http.get(
  `${api}/top-doctors`,
  { params: { ...options } })

const apiTopPacientes = (options) => http.get(`${api}/top-pacientes`, { params: { ...options } })
const apiTopPacientesDetail = (options) => http.get(`${api}/top-pacientes-detail`, { params: { ...options } })

const apiTopIps = (options = {}) => http.get(`${api}/top-ips`, { params: { ...options } })
const apiResumeTotales = (options) => http.get(
  `${api}/totals`,
  { params: { ...options } })

const apiPatologia = (options = {}) => http.get(`${api}/consumption-pathology`, { params: { ...options } })

const apiTecnicoVigilancia = (queryParams = {}) => http.get(`${api}/blood-glucose-chart`, { params: { ...queryParams } })
const apiTopTecnicoVigilancia = (queryParams = {}) => http.get(`${api}/top-blood-glucose-chart`, { params: { ...queryParams } })

const apiFarmacoVigilancia = (queryParams = {}) => http.get(`${api}/interactions-chart`, { params: { ...queryParams } })
const apiTopFarmacoVigilancia = (queryParams = {}) => http.get(`${api}/topinteractions-chart`, { params: { ...queryParams } })
const apiTopFarmacoVigilanciaMayor = (queryParams = {}) => http.get(`${api}/topinteractions-mayor`, { params: { ...queryParams } })
const apiTopFarmacoVigilanciaDescription = (queryParams = {}) => http.get(`${api}/topinteractions-description`, { params: { ...queryParams } })
const apiTopPoliformulados = (options = {}) => http.get(`${api}/top-poliformulados`, { params: { ...options } })

const apiPacientesDiagnostico = (options = {}) => http.get(`${api}/pacientes-diagnostico`, { params: { ...options } })
const apiPacientesPoliformulados = (options = {}) => http.get(`${api}/pacientes-poliformulados`, { params: { ...options } })
const apiAnalisispoliformulacion = (options = {}) => http.get(`${api}/analisis-poliformulacion`, { params: { ...options } })

// indicadores clientes
const apiclasificacionTipoPqr = (queryParams = {}) => http.get(`${apiExecutionReportClient}/clasificacion-tipo-pqr`, { params: { ...queryParams } })
const apiclasificacionPqr = (queryParams = {}) => http.get(`${apiExecutionReportClient}/clasificacion-pqr`, { params: { ...queryParams } })
const apiClientePqr = (queryParams = {}) => http.get(`${apiExecutionReportClient}/cliente-pqr`, { params: { ...queryParams } })
const apiClienteTipoPqr = (queryParams = {}) => http.get(`${apiExecutionReportClient}/cliente-tipo-pqr`, { params: { ...queryParams } })
const apiMedioPqr = (queryParams = {}) => http.get(`${apiExecutionReportClient}/medio-pqr`, { params: { ...queryParams } })
const apiMedioClientePqr = (queryParams = {}) => http.get(`${apiExecutionReportClient}/medio-cliente-pqr`, { params: { ...queryParams } })
const apiPacientesAtendidos = (queryParams = {}) => http.get(`${apiExecutionReportClient}/pacientes-atendidos`, { params: { ...queryParams } })
const apiMipres = (queryParams = {}) => http.get(`${apiExecutionReportClient}/mipres`, { params: { ...queryParams } })

// historia clinica
const apiHistoriaClinica = (queryParams = {}) => http.get(`${apiExecutionReportClient}/historia-clinica`, { params: { ...queryParams } })
const apiSolicitides = (queryParams = {}) => http.get(`${apiExecutionReportClient}/solicitudes`, { params: { ...queryParams } })
export {
  apiSelectFilter,
  apiAuthorizationReason,
  apiTopDoctors,
  apiTopPacientes,
  apiTopPacientesDetail,
  apiTopIps,
  apiResumeTotales,
  apiPatologia,
  apiTecnicoVigilancia,
  apiTopTecnicoVigilancia,
  apiFarmacoVigilancia,
  apiTopFarmacoVigilancia,
  apiTopFarmacoVigilanciaMayor,
  apiTopFarmacoVigilanciaDescription,
  apiTopPoliformulados,
  apiPacientesDiagnostico,
  apiPacientesPoliformulados,
  apiAnalisispoliformulacion,
  apiclasificacionTipoPqr,
  apiclasificacionPqr,
  apiClientePqr,
  apiClienteTipoPqr,
  apiMedioPqr,
  apiMedioClientePqr,
  apiPacientesAtendidos,
  apiMipres,
  apiHistoriaClinica,
  apiSolicitides
}
