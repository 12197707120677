
<template>
  <section>
    <div class="card border-b">
      <Divider align="left" type="dashed" id="divider-medicos">
        <b>Pacientes</b>
      </Divider>
      <DataTable
        :value="customers1"
        v-model:expandedRows="expandedRows"
        :loading="loading"
        responsiveLayout="scroll"
        showGridlines
        scrollHeight="380px"
        class="p-datatable-sm"
        :scrollable="true"
      >
        <template #empty>No se encontraron pacientes</template>
        <template #loading>Cargando...</template>
        <Column :expander="true" headerStyle="width: 5%" style="min-width: 5%; font-size:11px;"/>
        <Column field="Medico" header="Profesional"  style="min-width: 45%; font-size:11px;">
          <template #body="{data}">{{data.Paciente}}</template>
        </Column>
        <Column header="Items" headerStyle="width: 10%; justify-content: center" bodyStyle="justify-content:flex-end ; overflow: visible;min-width: 10%;" style="min-width: 10%; font-size:11px;">
          <template #body="{data}">{{data.Items}}</template>
        </Column>
        <Column header="Costo" headerStyle="width: 20%; justify-content: center" bodyStyle="justify-content:flex-end ; overflow: visible;min-width: 20%;" style="min-width: 20%; font-size:11px;">
          <template #body="{data}">{{data.costoFormat}}</template>
        </Column>
        <Column header="Total" headerStyle="width: 20%; justify-content: center" bodyStyle="justify-content:flex-end ; overflow: visible;min-width: 20%;" style="min-width: 20%; font-size:11px;">
          <template #body="{data}">{{data.totalFormat}}</template>
        </Column>
        <template #expansion="{data}">
          <div class="orders-subtable" style="width: 100%">
            <DataTable
              :value="data.children"
              showGridlines
              :scrollable="true"
              scrollHeight="150px"
              class="p-datatable-sm"
            >
              <Column header="Entidad" headerStyle=""  style="min-width: 50%; font-size:11px;">
                <template #body="{data}">
                  {{data.Medicamento}}
                </template>
              </Column>
              <Column header="Items" headerStyle="width: 10%; text-align: center" bodyStyle="text-align: right; overflow: visible; justify-content: flex-end;" style="font-size:11px; min-width: 10%">
                <template #body="{data}">
                  {{data.Items}}
                </template>
              </Column>
              <Column header="Costo" headerStyle="width: 20%; text-align: center" bodyStyle="text-align: right; overflow: visible; justify-content: flex-end;" style="font-size:11px; min-width: 20%">
                <template #body="{data}">
                  {{data.costoFormat}}
                </template>
              </Column>
              <Column header="Total" headerStyle="width: 20%; text-align: center" bodyStyle="text-align: right; overflow: visible; justify-content: flex-end;" style="font-size:11px; min-width: 20%">
                <template #body="{data}">
                  {{data.totalFormat}}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </section>
</template>
<script>
import { ref, onMounted } from 'vue'
import { apiTopPacientes, apiTopPacientesDetail } from '../../../../services'
export default {
  name: 'VisualizarTablaPacientes',
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const ValidateDarkMode = () => {
      const dark = document.querySelector('html').getAttribute('class')
      if (dark) {
        document.getElementById('divider-medicos').classList.add('divider-customer')
      }
    }
    const datosDetalle = (dato) => {
      var filtros = props.client
      dato.map((pac, key) => {
        // console.log('item', pac)
        filtros.ced = pac.Cedula
        apiTopPacientesDetail(filtros).then(({ data }) => {
          customers1.value[key].children = data
          // loading.value = false
        }).catch((e) => {
          console.error(e)
        })
      })
    }
    onMounted(() => {
      ValidateDarkMode()
      loading.value = true
      apiTopPacientes(props.client).then(({ data }) => {
        customers1.value = data
        datosDetalle(data)
        loading.value = false
      }).catch((e) => {
        console.error(e)
      })
    })
    const customers1 = ref([])
    // const datosPacientes = ref([])
    const expandedRows = ref([])
    const loading = ref(false)
    return { datosDetalle, customers1, loading, expandedRows }
  }
}
</script>
