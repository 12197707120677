<template>
  <section class="vizualizar-detalle">
    <Sidebar
    v-model:visible="visible"
    :baseZIndex="1000"
    :position="sidebarPosition"
    class="dark:bg-dark-1"
    :class="sidebarClass"
    >
    <button
        type="button"
        class="bg-green-600 text-white px-3 py-2 rounded-md btn-sm"
        @click="handleDownloadXls(props.client)"
      >
        Descargar Excel
      </button>
      <button
        type="button"
        class="bg-primary-1 text-white px-3 py-2 ml-3 rounded-md btn-sm"
        @click="handleDownloadPdf(props.client)"
      >
        Descargar PDF
      </button>
      <Ips style="width: 100%;" :client="props.client"/>
      <Doctors style="width: 100%;" :client="props.client"/>
      <Pacientes style="width: 100%;" :client="props.client"/>
      <div class="w-full mb-3">
        <TopTecnicoVigilancia :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopFarmacoVigilancia :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopFarmacoVigilanciaMayor :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopFarmacoVigilanciaDescripcion :client="props.client" />
      </div>
      <div class="w-full mb-3">
        <TopPoliformulados :client="props.client" />
      </div>
    </Sidebar>
  </section>
</template>
<script>
import { ref, reactive } from 'vue'
import Doctors from './components/medicos'
import Pacientes from './components/pacientes'
import Ips from './components/ips'
import axios from 'axios'
import TopTecnicoVigilancia from './components/topTecnoVigilancia'
import TopFarmacoVigilancia from './components/topFarmacoVigilancia'
import TopFarmacoVigilanciaMayor from './components/topFarmacoVigilanciaMayor'
import TopFarmacoVigilanciaDescripcion from './components/topFarmacoVigilanciaDescripcion'
import TopPoliformulados from './components/topPoliformulados'

export default {
  name: 'VizualizarDetalle',
  components: {
    Doctors,
    Pacientes,
    Ips,
    TopTecnicoVigilancia,
    TopFarmacoVigilancia,
    TopFarmacoVigilanciaMayor,
    TopFarmacoVigilanciaDescripcion,
    TopPoliformulados
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const visible = ref(false)
    const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
    const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
    /** methods **/
    // eslint-disable-next-line no-return-assign
    const toggle = () => visible.value = !visible.value

    const filters = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null,
      regimen: null,
      genero: null,
      edad1: null,
      edad2: null
    })
    const handleDownloadXls = (filtro) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-detalle-xls`
      const res = axios.getUri({ url, params: filtro })
      window.open(res, '_blank')
      if (filters.client !== null) {
      }
    }
    const handleDownloadPdf = (filtro) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-detalle-pdf`
      const res = axios.getUri({ url, params: filtro })
      window.open(res, '_blank')
      if (filters.client !== null) {
      }
    }

    /** returns **/

    return {
      visible,
      toggle,
      sidebarPosition,
      props,
      sidebarClass,
      handleDownloadXls,
      handleDownloadPdf
    }
  }
}
</script>
